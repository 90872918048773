html {
  font-family: "Roboto";
}

h1 {
  color: #fff;
}

body {
  background-color: #2b2f37;
}

.login_bar {
  width: 1200px;
}

.logo {
  max-width: 500px;
  width: 100%;
}

.login_header {
  max-width: 1000px;
  height: 100vh;
  min-height: 400px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.login_heading {
  color: #e9e8e8;
  font-family: "Roboto Slab", serif;
  font-size: 30pt;
}

.login_btn:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

.bar_chart {
  background: #33353e;
  display: flex;
  justify-content: space-around;
  box-shadow: 1px 3px 5px #1d1c1c;
}

.dash_page {
  margin-top: 50px;
}

.pie_charts {
  margin-bottom: 100px;
  display: flex;
  background: #33353e;
  justify-content: center;
  box-shadow: 1px 3px 5px #1d1c1c;
}

.admin_page {
  background-color: #33353e;
  padding: 20px;
  margin: 50px auto 0 auto;
}

.user_types_form {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.full_screen_modal {
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: #000000e0;
  color: white;
  z-index: 9999;
}
.full_screen_modal_inner {
  min-width: 490px;
}
.full_screen_modal form {
  display: flex;
  justify-content: space-between;
}
.full_screen_modal button {
  cursor: pointer;
}

.close_modal {
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;
  z-index: 3;
}

.edit_icon {
  cursor: pointer;
  margin: 0 10px;
}

.pass_icon {
  cursor: pointer;
}

.delete_icon {
  cursor: pointer;
}
/*# sourceMappingURL=index.80ce8ced.css.map */
